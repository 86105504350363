<template>
  <div>
    <div class="header_box">
      <div class="contenter">
        <div class="logo_box">
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <ul class="tab_list">
          <li
            class="tab_item"
            :class="active == '1' ? 'active' : ''"
            @click="changeTab(1)"
          >
            首页
          </li>
          <li
            class="tab_item"
            :class="active == '2' ? 'active' : ''"
            @click="changeTab(2)"
          >
            服务
          </li>
          <li
            class="tab_item"
            :class="active == '3' ? 'active' : ''"
            @click="changeTab(3)"
          >
            团队
          </li>
          <li
            class="tab_item"
            :class="active == '4' ? 'active' : ''"
            @click="changeTab(4)"
          >
            客户
          </li>
          <li
            class="tab_item"
            :class="active == '5' ? 'active' : ''"
            @click="changeTab(5)"
          >
            研究
          </li>
          <li
            class="tab_item"
            :class="active == '6' ? 'active' : ''"
            @click="changeTab(6)"
          >
            联系我们
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    active: String,
  },
  data() {
    return {};
  },
  methods: {
    changeTab(key) {
      switch (key) {
        case 1:
          console.log(key);

          this.$router.push("/");
          break;
        case 2:
          console.log(key);
          this.$router.push("/service");
          break;
        case 3:
          console.log(key);
          // this.$router.push("/team");
          break;
        case 4:
          console.log(key);
          this.$router.push("/");
          // this.$router.push("/customer");
          break;
        case 5:
          console.log(key);
          this.$router.push("/");
          break;
        case 6:
          console.log(key);
          this.$router.push("/");
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #111;
  z-index: 5;
  .contenter {
    display: flex;
    justify-content: space-between;
    .logo_box {
      height: 100px;
      display: flex;
      align-items: center;
      img {
        height: 50px;
      }
    }
    .tab_list {
      display: flex;
      line-height: 100px;
      color: #fff;
      .tab_item {
        padding: 0 35px;
        margin-left: 10px;
        font-size: 16px;
        &.active,
        &:hover {
          background-color: rgba(52, 118, 254, 0.4);
        }
      }
    }
  }
}
</style>