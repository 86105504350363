<template>
  <div>
    <div class="footer_box">
      <div class="contenter">
        <div class="footer_left">
          <p class="name">
            <img src="../assets/images/saibo_logo.png" alt="" />
            北京赛博英杰科技有限公司
          </p>
          <ul class="info_list">
            <li class="info">
              <div class="slot">电话：</div>
              <div class="masg">+86-010-84161293</div>
            </li>
            <li class="info">
              <div class="slot">传真：</div>
              <div class="masg">+86-010-84161293</div>
            </li>
            <li class="info">
              <div class="slot">邮箱：</div>
              <div class="masg">connect@geniuscybertech.com</div>
            </li>
            <li class="info">
              <div class="slot">地址：</div>
              <div class="masg">
                北京市朝阳区望京利泽中二路1号中辰大厦二层
                <br />
                辽宁省沈阳市浑南新区沈本大街东200米沈阳国际软件园E03<br />
                河南省信息安全产业示范基地5号楼303、304室
              </div>
            </li>
          </ul>
        </div>
        <div class="footer_right">
          <ul class="qr_code_list">
            <li class="qr_code">
              <img src="../assets/images/saibo.png" alt="" />
              赛博英杰
            </li>
            <li class="qr_code">
              <img src="../assets/images/shushuo.png" alt="" />
              数说安全
            </li>
            <li class="qr_code">
              <img src="../assets/images/zhengqi.png" alt="" />
              正奇学苑
            </li>
          </ul>
        </div>
      </div>
      <div class="baian">
        <a href="https://beian.miit.gov.cn/#/" target="_blank">京ICP备19027240号 </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.footer_box {
  position: relative;
  top: -1px;
  width: 100%;
  min-width: 1200px;
  height: 635px;
  background-color: #222;
  color: #fff;
  padding-top: 40px;
  .contenter {
    border-top: 2px solid #666;
    display: flex;
    justify-content: space-between;
    .footer_left {
      padding-top: 84px;
      .name {
        font-size: 24px;
        img {
          margin-right: 10px;
          width: 49px;
        }
      }
      .info_list {
        padding-left: 10px;
        margin-top: 30px;
        .info {
          display: flex;
          width: 769px;
          line-height: 36px;
          .slot {
            width: 50px;
          }
        }
      }
    }
    .footer_right {
      margin-top: 40px;
      .qr_code_list {
        width: 95px;
        .qr_code {
          text-align: center;
          font-size: 12px;
          line-height: 30px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
.baian {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
  text-align: center;
  margin-top: 30px;
  a {
    font-size: 14px;
    color: #f1f1f1;
    &:hover {
      text-decoration: underline;
      color: rgb(122, 157, 255);
    }
  }
}
</style>