<template>
  <div>
    <Header :active="active" />
    <div class="banner_box">
      <div class="info">
        <h3 class="banner_title">客户</h3>
        <p>
          网络安全战略咨询<br />
          我们为76家企业提供服务
        </p>
      </div>
      <div class="more_btn">
        <i class="icon el-icon-arrow-down"></i>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "customer",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      active: "4",
    };
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>
<style lang="less" scoped>
@import url("../assets/css/customer.less");
</style>